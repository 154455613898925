<template>
  <div class="work-and-shop">
    <Breadcrumb :items="breadcrumb" />
    <v-container fluid>
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
      />
      <CategoryTitle :category="category" />
      <ShopAtWorkSubscription />
    </v-container>
  </div>
</template>
<style lang="scss">
.address-selector-view {
  .address-selector {
    padding: 0px;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import categoryMixins from "~/mixins/category";
import ShopAtWorkSubscription from "@/components/wordpress/ShopAtWorkSubscription.vue";

export default {
  name: "WorkAndShop",
  mixins: [categoryMixins],
  components: {
    CategoryTitle,
    Breadcrumb,
    ShopAtWorkSubscription
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>
